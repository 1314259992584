/* You can add global styles to this file, and also import other style files */

@import "../node_modules/ng-uikit-pro-standard/assets/scss/core/mixins";
@import "../node_modules/ng-uikit-pro-standard/assets/scss/core/colors";
@import "../node_modules/ng-uikit-pro-standard/assets/scss/core/variables";
@import "../node_modules/ng-uikit-pro-standard/assets/scss/core/variables-pro";
@import "../node_modules/ng-uikit-pro-standard/assets/scss/core/msc/skins-pro";

:root {
  //-- Body
  --BackgroundColor: #041333;
  --TextColor: #ffffff;
  --TextColor40Alpha: #ffffff;
  --PrimaryColor: #1b233a;
  --TextColorOnPrimary: #ffffff;
  --AccentColor: #ff005b;
  --TextColorOnAccent: #ffffff;
  --FooterBgColor: #1b233a;
  --FooterFontColor: #ffffff;

  //-- Side Navbar and menu item
  --SideNavbarBgColor: #1b233a;
  --SideNavbarTextColor: #ffffff;
  --SideNavbarMenuItemHoverBgColor: #ff005b;
  --SideNavbarMenuItemHoverTextColor: #ffffff;
  --SideNavbarActiveMenuItemBgColor: #ff005b;
  --SideNavbarActiveMenuItemTextColor: #ffffff;

  //-- Side navbar sub-menu item
  --SideNavbarSubMenuBgColor: #1b233a;
  --SideNavbarSubMenuTextColor: #ffffff;
  // --SideNavbarSubMenuItemHoverBgColor: #ff005b;
  --SideNavbarSubMenuItemHoverTextColor: #ffffff;
  // --SideNavbarActiveSubMenuItemBgColor: #ff005b;
  --SideNavbarActiveSubMenuItemTextColor: #ffffff;

  //-- Top Navbar and menu item
  --TopNavbarBgColor: #1b233a;
  --TopNavbarTextColor: #ffffff;
  --TopNavbarMenuItemHoverBgColor: #ff005b;
  --TopNavbarMenuItemHoverTextColor: #ffffff;

  //-- Top Navbar sub-menu item
  --TopNavbarSubMenuBgColor: #1b233a;
  --TopNavbarSubMenuTextColor: #ffffff;
  --TopNavbarSubMenuItemHoverBgColor: #ff005b;
  --TopNavbarSubMenuItemHoverTextColor: #ffffff;

  //-- Scrollbar styling
  --ScrollbarBackgroundColor: #ffffff;
  --ScrollbarThumbColor: #ff005b;

  //-- Font family
  --FontFamily: "Mulish", sans-serif;

  //-- Divider color
  --DividerColor: rgba(0, 0, 0, 0.1);

  //-- Card styling
  --CardBackgroundColor: #04102b;
  --CardBackground20Alpha: #04102b20;
  --CardTextColor20Alpha: #ffffff20;
  --CardTextColor: #ffffff;
}

$skins: () !default;
$skins: map-merge(
  (
    "custom": (
      //-- Body background
      "skin-background-color": var(--BackgroundColor),
      //-- Footer background
      "skin-footer-color": var(--FooterBgColor),
      //-- Text color
      "skin-text": var(--TextColor),
      //-- Accent color
      "skin-accent": var(--AccentColor),
      //--Primary color
      "skin-primary-color": var(--PrimaryColor),
      "skin-gradient-start": var(--PrimaryColor),
      "skin-gradient-end": var(--AccentColor),
      // "skin-mask-slight": green,
      // "skin-mask-light": #fff,
      // "skin-mask-strong": #fff,
      "skin-btn-primary": #4285f4,
      "skin-btn-secondary": #aa66cc,
      "skin-btn-default": #2bbbad,
      //#region Side navbar//
      //-- Side navbar
      "skin-side-navbar-bg": var(--SideNavbarBgColor),
      //-- Side navbar text
      "skin-side-navbar-text": var(--SideNavbarTextColor),
      //-- Side navbar active bg
      "skin-side-navbar-active-bg": var(--SideNavbarActiveMenuItemBgColor),
      //-- Side navbar active text
      "skin-side-navbar-active-text": var(--SideNavbarActiveMenuItemTextColor),
      //-- Side navbar item hover
      "skin-side-navbar-item-hover-bg": var(--SideNavbarMenuItemHoverBgColor),
      //-- Side navbar item hover text
      "skin-side-navbar-item-hover-text": var(--SideNavbarMenuItemHoverTextColor),
      //-- Side navbar sub-menu text
      "skin-side-navbar-sub-menu-text": var(--SideNavbarSubMenuTextColor),
      //-- Side navbar sub-menu hover text
      "skin-side-navbar-sub-menu-hover-text": var(--SideNavbarSubMenuItemHoverTextColor),
      //--
      "skin-side-navbar-sub-menu-active-text": var(--SideNavbarActiveSubMenuItemTextColor),
      //#endregion Side navbar//
      //#region Top Navbar//
      //-- Top navbar
      "skin-top-navbar-bg": var(--TopNavbarBgColor),
      //-- Top navbar text
      "skin-top-navbar-text": var(--TopNavbarTextColor),
      //-- Top navbar menu item hover bg
      "skin-top-navbar-menu-item-hover-bg": var(--TopNavbarMenuItemHoverBgColor),
      //-- Top navbar menu item hover text
      "skin-top-navbar-menu-item-hover-text": var(--TopNavbarMenuItemHoverTextColor),
      //-- Top navbar sub menu bg
      "skin-top-navbar-submenu-bg": var(--TopNavbarSubMenuBgColor),
      //-- Top navbar sub menu text
      "skin-top-navbar-submenu-text": var(--TopNavbarSubMenuTextColor),
      //-- Top navbar sub menu item hover bg
      "skin-top-navbar-submenu-item-hover-bg": var(--TopNavbarSubMenuItemHoverBgColor),
      //-- Top navbar sub menu item hover text
      "skin-top-navbar-submenu-item-hover-text": var(--TopNavbarSubMenuItemHoverTextColor),
      //#endregion Top Navbar//
    ),
  ),
  $skins
);

//-- For Scheduler - Starts
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-angular-schedule/styles/material.css";
//-- For Scheduler - Ends




body.modal-open {
  overflow: hidden !important;
  padding-right: 12px !important;
}

.main-frame {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.cdk-overlay-container {
  z-index: 1040 !important;
}

.side-nav .logo-wrapper,
.side-nav .logo-wrapper a {
  height: 100px !important;
}

.fixed-sn main {
  padding-top: 4rem !important;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.overflow-wrap-normal {
  overflow-wrap: normal;
}

.fit-content {
  width: fit-content;
  width: -moz-fit-content;
}

.spaced-text {
  letter-spacing: 1px;
}

body {
  font-family: var(--FontFamily);
  font-size: 0.8rem;
}

.mdb-select-value,
.md-form label {
  font-size: 0.8rem !important;
}

.modal-content,
.mdb-select-dropdown {
  background-color: var(--BackgroundColor) !important;
}

.modal-content .modal-header {
  background-color: var(--TopNavbarBgColor) !important;
  color: var(--TopNavbarTextColor) !important;
  border: 0 !important;
}

.mdb-option {
  color: var(--TextColor) !important;
}

.mdb-option.selected {
  background-color: transparent !important;
}

.mdb-option.active,
.mdb-option.selected.active,
.mdb-option:hover {
  background-color: transparent !important;
  border-top: 1px;
  border-top-style: solid;
  border-bottom: 1px;
  border-bottom-style: solid;
  border-color: var(--DividerColor);
}

.drop-shadow {
  // filter: drop-shadow(0px 0px 6px grey);
  filter: drop-shadow(0 0 1px grey);
}

.image-substituted {
  @extend .drop-shadow;
  height: auto !important;
  margin: auto !important;
}

.custom-skin {
  background-color: var(--BackgroundColor) !important;
  color: var(--TextColor) !important;
}

.custom-skin .page-footer {
  font-size: 0.9rem;
}

.object-contain {
  object-fit: contain !important;
}

.object-cover {
  object-fit: cover !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.break-word {
  word-break: break-word;
}

.pointer-events-none {
  pointer-events: none;
}

.h-100vh {
  height: 100vh !important;
}

@media (max-width: 992px) {
  .modal .modal-full-height.modal-lg {
    max-width: 100% !important;
    width: 100% !important;
  }
}

.modal-dialog .modal-content {
  border-radius: 0 !important;
}

.custom-skin .accent {
  background: var(--AccentColor) !important;
  color: var(--TextColorOnAccent) !important;
}

.btn-floating i {
  color: var(--TextColorOnAccent);
}
.custom-skin .primary {
  & .btn-floating i {
    color: var(--TextColorOnAccent);
  }
}
.custom-skin .accent-font {
  color: var(--AccentColor) !important;
}

.divider-font {
  color: var(--DividerColor) !important;
}

.custom-skin .primary {
  background: var(--PrimaryColor) !important;
  color: var(--TextColorOnPrimary) !important;
}

.custom-skin .primary-font {
  color: var(--PrimaryColor) !important;
}
// .pagination.pagination-circle .page-item .page-link{
//     width: 34px;
//     text-align: center;
//     background-color: var(--AccentColor);
//     color: white;
// }

.completer-dropdown {
  z-index: 1032 !important;
}

.custom-scrollbar::-webkit-scrollbar-track,
.e-content-wrap::-webkit-scrollbar-track,
.e-appointment-wrap::-webkit-scrollbar-track,
.side-nav-content::-webkit-scrollbar-track,
.completer-dropdown::-webkit-scrollbar-track,
.mdb-select-custom-content::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  // background-color: var(--ScrollbarBackgroundColor);
  background-color: var(--BackgroundColor);
}

.invisible-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: var(--ScrollbarThumbColor) var(--BackgroundColor);
}

.custom-scrollbar::-webkit-scrollbar,
.side-nav-content::-webkit-scrollbar,
.e-appointment-wrap::-webkit-scrollbar,
.completer-dropdown::-webkit-scrollbar,
.mdb-select-custom-content::-webkit-scrollbar {
  width: 6px;
  scrollbar-width: 6px;
  height: 6px;
  background-color: var(--ScrollbarThumbColor);
}

.invisible-scrollbar::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background-color: transparent;
}

.e-content-wrap::-webkit-scrollbar {
  width: 17px;
  height: 6px;
  background-color: var(--ScrollbarThumbColor);
}

.custom-scrollbar::-webkit-scrollbar-thumb,
.e-content-wrap::-webkit-scrollbar-thumb,
.e-appointment-wrap::-webkit-scrollbar-thumb,
.side-nav-content::-webkit-scrollbar-thumb,
.completer-dropdown::-webkit-scrollbar-thumb,
.mdb-select-custom-content::-webkit-scrollbar-thumb,
.card-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: var(--ScrollbarThumbColor);
}

.invisible-scrollbar::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.card-scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: var(--CardBackgroundColor);
}

.custom-skin .side-nav .collapsible .card .active .card-header a h5 {
  color: var(--SideNavbarActiveMenuItemTextColor) !important;
}

//-- Sub menu styling
// .side-nav .collapsible .card-body li a {
//   background-color: var(--SideNavbarBgColor);
//   color: var(--SideNavbarTextColor);
// }
.side-nav .collapsible .card-body li a {
  height: 40px !important;
  line-height: 40px !important;
  border-radius: 0 !important;
  background-color: var(--SideNavbarSubMenuBgColor) !important;
  color: var(--SideNavbarSubMenuTextColor) !important;
  &:hover {
    color: var(--SideNavbarMenuItemHoverTextColor) !important;
    background-color: var(--SideNavbarMenuItemHoverBgColor) !important;
  }
}

// .side-nav .collapsible .card-body li a.active {
//   color: var(--SideNavbarActiveMenuItemBgColor) !important;
//   color: var(--SideNavbarActiveMenuItemTextColor) !important;
// }

//-- Styling for radio buttons - Starts
.form-check-input[type="radio"]:checked + label:after,
.form-check-input[type="radio"].with-gap:checked + label:after,
label.btn input[type="radio"]:checked + label:after,
label.btn input[type="radio"].with-gap:checked + label:after {
  background-color: var(--AccentColor);
}

.form-check-input[type="radio"]:checked + label:after,
.form-check-input[type="radio"].with-gap:checked + label:before,
.form-check-input[type="radio"].with-gap:checked + label:after,
label.btn input[type="radio"]:checked + label:after,
label.btn input[type="radio"].with-gap:checked + label:before,
label.btn input[type="radio"].with-gap:checked + label:after {
  border: 2px solid var(--AccentColor);
}

.form-check-input[type="radio"]:not(:checked) + label:before,
.form-check-input[type="radio"]:not(:checked) + label:after,
label.btn input[type="radio"]:not(:checked) + label:before,
label.btn input[type="radio"]:not(:checked) + label:after {
  border: 2px solid var(--DividerColor);
}
//-- Styling for radio buttons - Ends

//-- Styling for check
.form-check-input[type="checkbox"]:checked + label:before,
label.btn input[type="checkbox"]:checked + label:before {
  border-top-color: transparent !important;
  border-left-color: transparent !important;
}

.form-check-input[type="checkbox"] + label:before {
  border-color: var(--AccentColor) !important;
}

.navigation-links .card-header {
  color: var(--SideNavbarTextColor) !important;
  background-color: var(--SideNavbarBgColor) !important;
  &:hover {
    color: var(--SideNavbarMenuItemHoverTextColor) !important;
    background-color: var(--SideNavbarMenuItemHoverBgColor) !important;
  }
}

.card-header:first-child {
  border-radius: 0 !important;
}

.mdb-select-custom-content {
  max-height: 300px !important;
  overflow-y: auto;
}

hr {
  border-top: 1px solid var(--DividerColor) !important;
  display: flow-root;
}

.border {
  border: 1px solid var(--DividerColor) !important;
}

.border-top {
  border-top: 1px solid var(--DividerColor) !important;
}

.border-bottom {
  border-bottom: 1px solid var(--DividerColor) !important;
}

//-- Scheduler changes - Starts
.e-event-delete {
  display: none;
}
.e-control,
.e-css,
.e-toolbar .e-tbar-btn .e-tbar-btn-text {
  font-family: var(--FontFamily) !important;
}

.e-schedule,
.e-schedule .e-schedule-toolbar .e-toolbar-items,
.e-schedule .e-schedule-toolbar .e-tbar-btn,
.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text,
.e-toolbar .e-icons,
.e-schedule .e-month-agenda-view .e-appointment-wrap {
  background-color: var(--CardBackgroundColor) !important;
  color: var(--CardTextColor) !important;
}

.e-schedule .e-schedule-toolbar {
  border-bottom: 0.1px solid var(--DividerColor) !important;
  box-shadow: none;
}

.e-schedule .e-month-agenda-view .e-wrapper-container {
  & .e-appointment-wrap {
    min-height: 40vh;
  }
  min-height: 40vh;
}

.e-schedule .e-month-view .e-date-header-wrap table td,
.e-schedule .e-month-agenda-view .e-date-header-wrap table td,
.e-schedule .e-month-view .e-other-month,
.e-schedule .e-month-agenda-view .e-other-month,
.e-schedule .e-month-view .e-work-days,
.e-schedule .e-month-agenda-view .e-work-days,
.e-schedule .e-month-view .e-work-cells,
.e-schedule .e-month-agenda-view .e-work-cells {
  background-color: var(--CardBackgroundColor) !important;
  color: var(--CardTextColor) !important;
  border-color: var(--DividerColor) !important;
}

.e-schedule .e-month-agenda-view .e-date-time,
.e-schedule .e-month-agenda-view .e-subject,
.e-schedule .e-month-agenda-view .e-no-event,
.e-schedule .e-month-view .e-more-indicator {
  color: var(--CardTextColor);
}

.e-schedule .e-month-agenda-view .e-appointment:hover,
.e-schedule .e-month-agenda-view .e-appointment.e-appointment-border,
.e-schedule .e-month-agenda-view .e-appointment:focus,
.e-schedule .e-month-view .e-selected-cell,
.e-schedule .e-month-agenda-view .e-selected-cell {
  background: var(--DividerColor) !important;
}

.e-schedule .e-month-view .e-appointment {
  background: var(--PrimaryColor);
  color: var(--TextColorOnPrimary);
}

.e-schedule .e-month-view .e-appointment,
.e-schedule .e-month-agenda-view .e-appointment {
  cursor: pointer;
}
.e-calendar .e-content td.e-selected span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-selected span.e-day,
.e-schedule .e-month-agenda-view .e-appointment-indicator,
.e-schedule .e-month-view .e-current-date .e-date-header,
.e-schedule .e-month-agenda-view .e-current-date .e-date-header {
  background-color: var(--AccentColor) !important;
  color: var(--TextColorOnAccent) !important;
}

.e-schedule .e-schedule-toolbar .e-active-view .e-tbar-btn-text,
.e-schedule .e-schedule-toolbar .e-active-view .e-icons {
  color: var(--AccentColor) !important;
}

.e-schedule .e-month-agenda-view .e-appointment {
  border-left: 4px solid var(--AccentColor);
}

.e-dialog .e-footer-content {
  padding-bottom: 16px;
}

.e-dlg-content .e-tooltip-wrap.e-popup {
  background-color: #fcdbe4 !important;
  border-color: #fcdbe4 !important;
}

.e-tooltip-wrap.e-schedule-error .e-tip-content,
.e-tooltip-wrap.e-schedule-error .e-tip-content label {
  margin-bottom: 0;
}

.e-tooltip-wrap.e-popup {
  margin: 0;
  padding: 0;
  background: var(--PrimaryColor);
  opacity: 1;
  border-radius: 4px;
  border: 2px solid var(--PrimaryColor);
  font-family: var(--FontFamily) !important;
}

.e-tooltip-wrap .e-tip-content,
input.e-input,
textarea.e-input,
.e-input-group,
.e-input-group.e-control-wrapper {
  font-family: var(--FontFamily) !important;
}

.md-form textarea.md-textarea {
  min-height: 60px;
}

.e-toolbar-right {
  display: none;
}
//-- Scheduler changes - Ends

.notification-icon {
  height: 40px;
  width: 40px;
  object-fit: contain;
}

.notification-image {
  height: 150px;
  width: 100%;
  object-fit: cover;
}

#toast-container > mdb-toast-component.primary {
  color: var(--TextColorOnPrimary) !important;
}

.btn.disabled, .btn:disabled {
  opacity: 0.35;
}

.mdl-progress.firebaseui-busy-indicator {
position: relative;
}

.sponsor-images {
  min-height: 86px;
  object-fit: contain;
  display: block;
  width: 100%;
  object-position: center;
  border-radius: 8px;
  height: 182px;
}

.ck-editor__main {
  textarea {
    height: 500px !important;
  }
}

.copy-to-clipboard {
  padding-left: 1%;

  .learn {
    font-family: "Mulish", sans-serif;

    h2 {
      font-size: 22px;
      font-weight: bold;

      a {
        color: #0e7fe1;
        text-decoration: underline;
        margin-left: 20px;
      }
    }
  }

  .clipboard-div {
    margin: 10px 0;

    .clipboard-row {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .clipboard-box {
        border-radius: 15px;
        height: 130px;
        padding: 15px 25px 10px 25px;
        margin-right: 25px;
        font-family: "Mulish", sans-serif;
        font-weight: bold;
        margin: 10px 25px 0 0;

        h2 {
          font-size: 20px;
          font-weight: bold;
        }
      }
    }
  }
}